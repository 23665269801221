import React from 'react';
import './motores.css';
import motores_estrategia from '../../../assets/motores_estrategia.webp';
import overlayImg from '../../../assets/titulo_comunicao.webp';
import titulo_marketing from '../../../assets/titulo_marketing.webp';
import titulo_gestao from '../../../assets/titulo_gestao.webp';
import orbita from '../../../assets/orbita.webp';
import capsula from '../../../assets/capsula.webp';
import carroespacial from '../../../assets/carroespacial.webp';
import busspacesite from '../../../assets/busspacesite.webp';
import capsula_sombra from '../../../assets/capsula_sombra.webp';
import globo_1 from '../../../assets/globo_1.webp';
import globo_2 from '../../../assets/globo_2.webp';
import Partners from '../partners/Partners';

const estiloImgBack = {
  backgroundColor: '#055ec4',
};

const Motores = () => {
  return (
    <div name='3motores' style={estiloImgBack} className="motores-background">
      <div className="borda-motores">
        <div className='motores-logo'>
          <img src={motores_estrategia} loading="eager"
            decoding="async" alt="motores imagem e estrategia" className="motores-estrategia-img" />
        </div>
        <div name='gestao' className='conteudo-gestao'>
          <div className='conteudo-gestao-esquerda'>
            <img src={titulo_gestao} loading="eager"
              decoding="async" alt="titulo gestao" className="titulo-gestao-img" />
            <hr className="linha-divisoria-laranja" />
            <h5 className='texto-header-gestao'>A GESTÃO EFICIENTE É FUNDAMENTAL PARA O CRESCIMENTO SUSTENTÁVEL DE QUALQUER ORGANIZAÇÃO.</h5>
            <p className='texto-p-gestao'>Nossa equipe oferece soluções personalizadas em gestão para otimizar processos, melhorar a eficiência operacional e promover uma cultura organizacional positiva.
              <br />
              Trabalhamos lado a lado com nossos clientes para entender seus desafios e desenvolver estratégias que realmente impulsionem o sucesso à longo prazo.
            </p>
          </div>
          <div className='conteudo-gestao-direita'>
            <img src={busspacesite} loading="eager"
              decoding="async" alt="bus space site" className="busspacesite-img" />
          </div>
        </div>
        <div className="linha-com-capsula">
          <hr className="linha-divisoria-branca-top" />
          <img src={capsula} loading="eager"
            decoding="async" alt="capsula laranja" className="capsula-laranja-img" />
        </div>
        <div name='comunicacao' className='conteudo-comunicacao'>
          <div className='conteudo-esquerda'>
            <img src={overlayImg} loading="eager"
              decoding="async" alt="titulo comunicação" className="titulo-comunicao-img" />
            <hr className="linha-divisoria-laranja" />
            <h5 className='texto-header'>ACREDITAMOS QUE A COMUNICAÇÃO EFICAZ É A CHAVE PARA O SUCESSO NOS NEGÓCIOS.</h5>
            <div className='icones-comunicacao'>
              <img src={globo_1} loading="eager"
                decoding="async" alt="globo 1" className="globo-1-img" />
              <img src={globo_2} loading="eager"
                decoding="async" alt="globo 2" className="globo-2-img" />
              <img src={orbita} loading="eager"
                decoding="async" alt="orbita" className="orbita-img" />
            </div>
            <p className='texto-p'> Nossa equipe especializada em comunicação, trabalha para criar conexões significativas entre empresas e seu público-alvo.
              <br />
              Desde a elaboração de mensagens claras e impactantes, até a implementação das estratégias de comunicação abrangentes para garantir que sua mensagem seja ouvida e compreendida.
            </p>
          </div>
          <div className='conteudo-direita'>
            <img src={carroespacial} loading="eager"
              decoding="async" alt="carro espacial" className="carro-espacial-img" />
          </div>
        </div>
        <div className="linha-com-capsula">
          <hr className="linha-divisoria-branca-bottom" />
          <img src={capsula} loading="eager"
            decoding="async" alt="capsula laranja" className="capsula-laranja-img" />
        </div>
        <div name='marketing' className='conteudo-marketing'>
          <div className='conteudo-marketing-esquerda'>
            <img src={titulo_marketing} loading="eager"
              decoding="async" alt="titulo marketing" className="titulo-marketing-img" />
            <hr className="linha-divisoria-laranja" />
            <h5 className='texto-header-marketing'>NO MUNDO DOS NEGÓCIOS, O MARKETING DESEMPENHA UM PAPEL CRUCIAL NA CONSTRUÇÃO DE MARCAS FORTES E NA AQUISIÇÃO DE CLIENTES.</h5>
            <p className='texto-p-marketing'>Nossa equipe de marketing desenvolve estratégias para impulsionar o crescimento dos nossos parceiros.
              <br />
              Estamos constantemente explorando novas técnicas de abordagem para garantir que sua marca destaque-se no mercado.
            </p>
          </div>
          <div className='conteudo-marketing-direita'>
            <img src={capsula_sombra} loading="eager"
              decoding="async" alt="capsula sombra" className="capsula-sombra-img" />
          </div>
        </div>
        <Partners />
      </div>
    </div>
  );
}

export default Motores;
