import React from "react";
import { ReactTyped } from 'react-typed';
import './home.css';
import { Link } from "react-scroll";
import WhatsAppButton from '../WhatsAppButton/WhatsAppButton';

const Home = () => {
  return (
    <div className="img-back">
      <div className="overlay-img">
        <div className="conteudo">
          <h1 className="main-text">NEW BUSINESS NETWORK</h1>
          <ReactTyped
            strings={['CONHEÇA OS TRÊS MOTORES QUE FAZEM OS NOSSOS PARCEIROS DECOLAREM!',
              'ESTÁ PRONTO PARA ESSA JORNADA? O CÉU É O LIMITE!',
              'NÃO PERCA TEMPO, JUNTE-SE A NÓS E FAÇA SUA EMPRESA DECOLAR!',
              'ALAVANQUE SEU NEGÓCIO COM A PROSPECTUS NEW BUSINESS NETWORK!',
              'CONHEÇA OS MOTORES QUE IMPULSIONAM NOSSOS PARCEIROS PARA O SUCESSO!',
              'VAMOS JUNTOS NESSA? APERTE OS CINTOS E PREPARE-SE PARA VOAR!'
            ]}

            typeSpeed={75}
            backSpeed={20}
            loop
            className="react-typed"
          />
          <div className="item-container">
            <p className="item-lista">
              <Link className='cursor-pointer' to="gestao" smooth={true} duration={500}>
                GESTÃO
              </Link>
            </p>
            <p className="item-lista">
              <Link className='cursor-pointer' to="comunicacao" smooth={true} duration={500}>
                COMUNICAÇÃO
              </Link>
            </p>
            <p className="item-lista">
              <Link className='cursor-pointer' to="marketing" smooth={true} duration={500}>
                MARKETING
              </Link>
            </p>
          </div>
          <hr className="linha-divisoria" />
          <h3 className="texto-borda">SOMOS UMA EQUIPE QUE ACREDITA NO PODER DA GESTÃO INTELIGENTE, COMUNICAÇÃO EFICAZ E ESTRATÉGIAS DE MARKETING INOVADORAS. NOSSO COMPROMISSO É AJUDAR EMPRESAS A ALCANÇAREM O SUCESSO, PROPORCIONANDO SOLUCÕES INTEGRADAS E PERSONALIZADAS PARA ATENDER ÀS SUAS NECESSIDADES ESPECÍFICAS.
          </h3>
          <button className="buttonpro">
            <span>
              <Link to='iniciarmissao' smooth={true} duration={500}>
                DESBRAVE O DESCONHECIDO
              </Link>
            </span>
          </button>
        </div>
      </div>
      <WhatsAppButton />
    </div>
  )
}

export default Home