import React from 'react';
import whatsappIcon from '../../../assets/whatsapp.webp';
import './WhatsAppButton.css'; // Arquivo CSS para estilos

const WhatsAppButton = () => {
  const phoneNumber = "5551997766970";
  const message = "Olá, quero ser um parceiro da Prospectus NBN! Por onde começo?";
  const encodedMessage = encodeURIComponent(message);
  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

  return (
    <a 
      href={whatsappLink} 
      className="whatsapp-button" 
      target="_blank" 
      rel="noopener noreferrer"
    >
      <img 
        src={whatsappIcon}  loading="eager"
        decoding="async" 
        alt="WhatsApp icone" 
      />
    </a>
  );
}

export default WhatsAppButton;
