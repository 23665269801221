import React from 'react';
import './footer.css';
import icone_astronauta from '../../../assets/icone_astronauta.webp';
import icone_foguete from '../../../assets/icone_foguete.webp';
import icone_lua from '../../../assets/icone_lua.webp';
import cod_barra from '../../../assets/cod_barra.webp';
import rede from '../../../assets/rede.webp';
import star from '../../../assets/star.webp';
import globo_1 from '../../../assets/globo_1.webp';
import globo_2 from '../../../assets/globo_2.webp';

const Footer = () => {
  const handleInstagramClick = () => {
    window.open('https://www.instagram.com/prospectusnbn', '_blank');
  };

  const handleFacebookClick = () => {
    window.open('https://www.facebook.com/prospectusnbn', '_blank');
  };

  return (
    <div className='footer-web'>
      <div className='grade'>
        <div className='primeira-grade'>
          <div className='primeira-grade-icones-esquerda'>
            <img src={icone_foguete} loading="eager"
            decoding="async"  alt="icone foguete" className='icone-foguete'/>
            <img src={icone_astronauta} loading="eager"
            decoding="async"  alt="icone astronauta" className='icone-astronauta'/>
            <img src={icone_lua} loading="eager"
            decoding="async"  alt="icone lua" className='icone-lua'/>
          </div>
          <hr className="linha-divisoria-laranja-vertical" />
          <h3 className='central-text'>NOSSAS REDES SOCIAIS</h3>
          <hr className="linha-divisoria-laranja-vertical" />
          <div className='primeira-grade-icones-direita'>
            <img src={star} loading="eager"
            decoding="async"  alt="icone foguete" className='star'/>
            <img src={globo_1} loading="eager"
            decoding="async"  alt="icone astronauta" className='globo-1'/>
            <img src={globo_2} loading="eager"
            decoding="async"  alt="icone lua" className='globo-2'/>
          </div>
        </div>
        <hr className="linha-divisoria-laranja-horizontal" />
        <div className='segunda-grade'>
          <button className='btn-footer' onClick={handleInstagramClick}>Instagram</button>
          <button className='btn-footer' onClick={handleFacebookClick}>Facebook</button>
        </div>
        <hr className="linha-divisoria-laranja-horizontal" />
        <div className='terceira-grade'>
          <img src={rede} loading="eager"
            decoding="async"  alt="rede" className='left-image' />
          <hr className="linha-divisoria-laranja-vertical" />
          <img src={cod_barra} loading="eager"
            decoding="async"  alt="código de barra" className='right-image' />
        </div>
        <hr className="linha-divisoria-laranja-horizontal" />
        <h3 className='direitos-reservados'>Todos os direitos Reservados®PNBN</h3>
        <hr className="linha-divisoria-laranja-horizontal" />
        <div className='quarta-grade'>
          <h3 className='cnpj'>Prospectus New Business Network LTDA 44.557.009/0001-73</h3>
          <h3 className='email'>contato@prospectusnbn.com.br</h3>
        </div>
      </div>
    </div>
  );
}

export default Footer;
