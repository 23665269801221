import { QRCodeSVG } from 'qrcode.react'
import React from 'react'
import './qrCode.css';

const QrCode = () => {
  return (
    <div className='qrcode-container'>
      <div className='qrcode-content'>
        <p className='main-text'>ESCANEIE MEU QR CODE!</p>
        <QRCodeSVG
          value='https://prospectusnbn.com.br/bio'
          size="256"
        />
      </div>
      <div className='footer'>
        <h3 className='direitos-reservados'>Todos os direitos Reservados®PNBN</h3>
      </div>
    </div>
  )
}

export default QrCode