import React from 'react';
import Analytics from '../../../assets/Google_Analytics.webp';
import FacebookPartners from '../../../assets/Facebook-Partners.webp';
import GoogleADS from '../../../assets/Google-ADS.webp';
import Meta from '../../../assets/Meta-Business-Partner.webp';
import Instagram from '../../../assets/Instagram-Partners1.webp';
import './partners.css'; 

const Partners = () => {
  return (
    <div className='logo-partners'>
        <img src={Analytics} loading="eager"
            decoding="async"  alt="google partner" />
        <img src={GoogleADS} loading="eager"
            decoding="async"  alt="google partner" />
        <img src={Meta} loading="eager"
            decoding="async"  alt="google partner" />
        <img src={FacebookPartners} loading="eager"
            decoding="async"  alt="google partner" />
        <img src={Instagram} loading="eager"
            decoding="async"  alt="google partner" />
    </div>
  )
}

export default Partners;
