import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import WebPage from './components/webPage/WebPage';
import BioLink from './components/bioLink/BioLink';
import QrCode from './components/qrCode/QrCode';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<WebPage />} />
        <Route path="/bio" element={<BioLink />} />
        <Route path="/qrcode" element={<QrCode />} />
        <Route path="*" component={() => <h1>404 Not Found</h1>} />
      </Routes>
    </Router>

  );
}

export default App;
