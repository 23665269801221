import React from 'react';
import './astronauta.css';
import prospectus_bege from '../../../assets/prospectus_bege.webp';
import star from '../../../assets/star.webp';
import sucesso from '../../../assets/sucesso.webp';
import astrolua from '../../../assets/astrolua.webp';

const Astronauta = () => {

    const estiloImgBack = {
        backgroundColor: '#055ec4',
    };

    return (
        <div style={estiloImgBack} className="astronauta-background">
            <img src={prospectus_bege} loading="eager"
            decoding="async"  alt="prospectus-bege" className="prospectus-bege" />
            <h2 className='astronauta-subtitulo'>TRABALHAMOS INCANSAVELMENTE PARA ENTENDER AS NECESSIDADES ÚNICAS DE CADA EMPRESA. DESENVOLVEMOS SOLUÇÕES PERSONALIZADAS QUE IMPULSIONAM RESULTADOS TANGÍVEIS.</h2>
                <div className="styled-box">&lt;html&gt; The <b>end</b> is always near. &lt;/html&gt;</div>
            <h4 className='astronauta-paragrafo'>Com uma abordagem integrada que une gestão inteligente, comunicação eficaz e estratégias de marketing. Estamos comprometidos em atingir seus objetivos e alcançar novos patamares de sucesso.</h4>
            <img src={star} loading="eager"
            decoding="async"  alt="star" className="img-star" />
            <img src={sucesso} loading="eager"
            decoding="async"  alt="sucesso" className="img-sucesso" />
            <div className="linha-divisoria-branca">
                {[...Array(9)].map((_, i) => (
                    <div key={i} className="linha-vertical"></div>
                ))}
            </div>
            <img src={astrolua} loading="eager"
            decoding="async"  alt="astronauta na lua" className="astrolua" />
        </div>
    );
};

export default Astronauta;
