import React, { useState, useEffect } from 'react';
import YouTube from 'react-youtube';
import './launcher.css';
import { Helmet } from 'react-helmet';

const Launcher = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isTabletOrDesktop, setIsTabletOrDesktop] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsTabletOrDesktop(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const videoId = isMobile ? 'NIpWDTqo9lM' : 'hNWbLuXDN6c';

  const opts = {
    height: '100%',
    width: '100%',
    playerVars: {
      autoplay: 1,
      controls: 0,
      modestbranding: 1,
      rel: 0,
      showinfo: 0,
      loop: 1,
      playsinline: 1,
    },
  };

  return (
    <div name='inicio' className='launcher-background'>
      <Helmet>
        <meta charSet='utf-8'/>
        <title>Gestão, Comunicação e Marketing</title>
        <link rel="canonical" href="https://www.prospectusnbn.com.br/" />
      </Helmet>
      <YouTube
        videoId={videoId}
        opts={opts}
        className={isTabletOrDesktop ? "fullscreen-bg" : "fullscreen-mobile-bg"}
      />
    </div>
  );
}

export default Launcher;
