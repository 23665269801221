import React from 'react';
import './wearTheFuture.css';
import prospectus_amarelo from '../../../assets/prospectus_amarelo.webp';
import pnbn_tira from '../../../assets/pnbn_tira.webp';
import astro_laranja from '../../../assets/astro-laranja.webp';

const WearTheFuture = () => {
  const phoneNumber = "5551997766970";
  const message = "Olá, quero ser um parceiro da Prospectus NBN! Por onde começo?";
  const encodedMessage = encodeURIComponent(message);
  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

  const WearTheFutureBackground = {
    backgroundColor: '#055ec4',
  };

  const openWhatsApp = () => {
    window.open(whatsappLink, '_blank');
  };

  return (
    <div name='iniciarmissao' style={WearTheFutureBackground}>
      <div className="borda-motores">
        <img
          src={astro_laranja} loading="eager"
          decoding="async" 
          alt="astro laranja"
          className='flutuante-img'
        />
        <img src={prospectus_amarelo} loading="eager"
            decoding="async"  alt="prospectus amarelo" className='prospectus-amarelo-img' />
        <p className="wear-the-future-text">
          Convidamos você a juntar-se nesta incrível jornada de crescimento e sucesso.
        </p>
        <p className="wear-the-future-text">
          Estamos aqui para transformar sua visão em realidade e contar a história de sucesso da sua empresa.
        </p>
        <p className="wear-the-future-text">
          Clique no botão abaixo e descubra o que você realmente precisa para ativar os motores da sua estratégia de sucesso!
        </p>
        <button className="buttonpro" onClick={openWhatsApp}>
          <span> INICIAR MISSÃO </span>
        </button>
      </div>
      <div>
        <img src={pnbn_tira} loading="eager"
            decoding="async"  alt="pnbn tira img" className='pnbn-tira-img' />
      </div>
    </div>
  );
};

export default WearTheFuture;
