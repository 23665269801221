import React from 'react'
import './computador.css';
import computador_fundo from '../../../assets/computador_fundo.webp';
import historia_sucesso from '../../../assets/historia_sucesso.webp';
import nosso_compromisso from '../../../assets/nosso_compromisso.webp';

const Computador = () => {
  const estiloImgBack = {
    backgroundColor: '#055ec4',
};

  return (
    <div style={estiloImgBack} className="computador-background">
        <img src={nosso_compromisso} loading="eager"
            decoding="async"  alt="nosso compromisso" className="nosso-compromisso" />
        <img src={computador_fundo} loading="eager"
            decoding="async"  alt="computador fundo" className="img-computador-fundo" />
        <img src={historia_sucesso} loading="eager"
            decoding="async"  alt="historia sucesso" className="historia-sucesso" />
    </div>
  )
}

export default Computador;
