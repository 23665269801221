import React from 'react';
import Navbar from './navbar/Navbar';
import Home from './home/Home';
import Motores from './motores/Motores';
import Computador from './computador/Computador';
import Astronauta from './astronauta/Astronauta';
import WearTheFuture from './wearTheFuture/WearTheFuture';
import Footer from './footer/Footer';
import Launcher from './launcher/Launcher';

function WebPage() {
  return (
    <div>
      <Navbar />
      <Launcher />
      <Home />
      <Motores />
      <Computador />
      <Astronauta />
      <WearTheFuture />
      <Footer />
    </div>
  );
}

export default WebPage;
